<template>
  <v-card>
    <v-toolbar color="secondary" flat dense>
      <v-toolbar-title>Schedule Call</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon to="/call-management">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- start of toolbar 2 -->
    <v-toolbar color="secondary" flat dense>
      <v-col>
        <v-autocomplete
          v-model="selectedOption"
          :items="saveOptions"
          label="Save Options"
          dense
          outlined
          @change="sendData"
        >
        </v-autocomplete>
      </v-col>
    </v-toolbar>
    <!-- end of toolbar 2 -->
    <v-card-text>
      <v-form>
        <v-row dense>
          <v-col cols="3">
            <v-text-field
              label="Code"
              small
              disabled
              v-model="record.ClgCode"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.Actions"
              :items="CallActions"
              item-text="value"
              item-value="name"
              label="Actions"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.CntctSbjct"
              :items="ActivitySubjects"
              item-text="Name"
              item-value="Code"
              label="Activity Subject"
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.CntctType"
              :items="ActivityActions"
              item-text="Name"
              item-value="Code"
              label="Activity Type"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.AttendUser"
              :items="Users"
              item-text="name"
              item-value="id"
              label="Attend User"
            ></v-autocomplete>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.CardCode"
              :items="bpMasterData"
              item-text="CardName"
              item-value="CardCode"
              label="Outlet"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-dialog
              ref="dialog"
              v-model="modal"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="record.Recontact"
                  label="Pick"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="record.Recontact" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <TimePicker
              v-model="record.StartTime"
              :id="1"
              :timing="`Start`"
              label="`StartTime`"
            ></TimePicker>
          </v-col>
          <!-- <v-col cols="3">
            <v-date-picker
              outlined
              dense
              @date="setDate"
              :myDate="record.CloseDate"
              v-model="record.CloseDate"
              :title="`Close Date`"
            ></v-date-picker>
          </v-col> -->
          <v-col cols="3">
            <v-dialog
              ref="dialog"
              v-model="modal2"
              :return-value.sync="date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="record.CloseDate"
                  label="Close Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  outlined
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="record.CloseDate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modal2 = false"> Cancel </v-btn>
                <v-btn text color="primary" @click="$refs.dialog.save(date)"> OK </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="3">
            <TimePicker
              v-model="record.EndTime"
              :id="1"
              :timing="`end`"
              label="`EndTime`"
            ></TimePicker>
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.Priority"
              :items="Priorities"
              item-text="name"
              item-value="value"
              label="Priority"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              v-model="record.Repeat"
              :items="repeatData"
              item-text="name"
              item-value="value"
              label="Repeat"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-text-field
              label="Remarks"
              small
              v-model="record.Details"
              outlined
              dense
            ></v-text-field>
          </v-col>
        </v-row>
        Details
        <!-- start of dialog -->
        <v-dialog v-model="dialog" width="650px">
          <v-card>
            <v-toolbar color="accent" dark dense>
              <v-toolbar-title>Select Territory</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click.native="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row>
                <!-- territories -->
                <v-col cols="12">
                  <v-treeview
                    color="accent"
                    :activatable="trueValue"
                    :hoverable="trueValue"
                    :selection-type="selectionType"
                    open-all
                    return-object
                    item-text="descript"
                    item-key="id"
                    :items="territories"
                    item-children="children_recursive"
                    :search="search"
                  >
                    <template v-slot:label="{ item }">
                      <div @click="selectItem(item)">
                        <span style="font-weight: bold">{{ item.descript }}</span>
                      </div>
                    </template>
                  </v-treeview>
                </v-col>
                <!-- end -->
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- end of dialog -->
      </v-form>
      <template class="ma-2">
        <v-tabs fixed-tabs background-color="primary" dark>
          <!-- Tab headers -->
          <v-tab>Sub Activities</v-tab>
          <v-tab>Objectives</v-tab>
          <v-tab>Linked Documents</v-tab>
          <v-tab>Notes</v-tab>
          <v-tab>Attachments</v-tab>

          <v-tab-item>
            <v-list-item> Sub Activity </v-list-item>
            <v-btn small @click.native="SubActDialog = true"> Add Sub Activity </v-btn>
            <v-data-table
              dense
              small
              :headers="SubActivityHeaders"
              :items="SubActivityRows"
              item-key="id"
              class="elevation-1"
            >
              <template v-slot:item.index="props">
                <v-btn @click="removeSubActRow(props.item.key)" dark color="red" text>
                  <v-icon>mdi-close-circle</v-icon></v-btn
                >
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <v-row class="mt-2" v-for="(row, index) in rows" :key="index">
              <v-col cols="6">
                <v-list-item>
                  Objective {{ ++index }}
                  <v-text-field dense v-model="row.Objective"></v-text-field>
                </v-list-item>
              </v-col>
              <v-col cols="3">
                <v-btn depressed small dark color="red" @click="removeRow(--index)">
                  <v-icon>mdi-close-circle</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-btn small color="primary" class="mt-2" @click="addRow"
              >Add Objective
            </v-btn>
          </v-tab-item>
          <v-tab-item>
            <template>
              <v-data-table
                dense
                small
                :headers="headers"
                :items="DocRows"
                item-key="id"
                class="elevation-1"
              >
                <template v-slot:item="{ item, index }">
                  <tr>
                    <td>{{ index + 1 }}</td>
                    <td>
                      <v-autocomplete
                        outlined
                        dense
                        v-model="item.DocType"
                        :items="documents"
                        item-text="DocumentName"
                        item-value="ObjectID"
                        label="Document"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <template>
                        <v-btn icon @click.native="openDialog(index)">
                          <v-icon>mdi-filter-menu</v-icon>
                        </v-btn>
                        <v-row justify="space-around">
                          <v-col cols="auto">
                            <v-dialog
                              transition="dialog-bottom-transition"
                              max-width="800"
                              v-model="item.dialogVisible"
                            >
                              <v-card>
                                <v-toolbar color="primary" dark>Filters</v-toolbar>
                                <v-card-text>
                                  <v-row class="pa-5 ma-2">
                                    <v-col cols="4">
                                      <v-checkbox
                                        v-model="item.Status"
                                        label="This BP documents"
                                        :true-value="1"
                                        :false-value="0"
                                      ></v-checkbox>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            label="Date  From"
                                            readonly
                                            v-on="on"
                                            dense
                                            v-model="item.StartDate"
                                            outlined
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="item.StartDate"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                    <v-col cols="4">
                                      <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                          <v-text-field
                                            dense
                                            label="Date To"
                                            readonly
                                            v-on="on"
                                            outlined
                                            v-model="item.EndDate"
                                          ></v-text-field>
                                        </template>
                                        <v-date-picker
                                          v-model="item.EndDate"
                                        ></v-date-picker>
                                      </v-menu>
                                    </v-col>
                                  </v-row>
                                </v-card-text>
                                <v-card-actions class="justify-end">
                                  <v-btn
                                    color="primary"
                                    @click="getSubItemsMaster(item, index)"
                                    >Fetch
                                  </v-btn>
                                  <v-btn
                                    color="red"
                                    dark
                                    @click.native="closeDialog(index)"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-col>
                        </v-row>
                      </template>
                    </td>
                    <td>
                      <v-autocomplete
                        outlined
                        dense
                        v-model="item.DocNum"
                        :items="item.DocItems"
                        :item-text="
                          (item) =>
                            item.DocNum +
                            '  -  ' +
                            item.CardName +
                            ' _ ' +
                            item.DocDate +
                            '  _  (' +
                            item.DocTotal +
                            ')'
                        "
                        item-value="DocNum"
                        label="Document Number"
                      ></v-autocomplete>
                    </td>
                    <td>
                      <v-btn small dark color="red" @click="removeDocRow(index)">
                        <v-icon>mdi-close-circle</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-btn color="primary" @click="addDocRow">Add Document</v-btn>
            </template>
          </v-tab-item>
          <v-tab-item>
            <div>
              <template>
                <v-container fluid>
                  <v-textarea
                    name="input-7-1"
                    filled
                    label="Notes"
                    auto-grow
                    v-model="record.Notes"
                  ></v-textarea>
                </v-container>
              </template>
            </div>
          </v-tab-item>
          <v-tab-item>
            <div>Content for Attachments</div>
          </v-tab-item>
        </v-tabs>
      </template>

      <!-- start of dialog -->
      <v-dialog v-model="SubActDialog" width="950px">
        <v-card>
          <v-toolbar color="accent" dark dense>
            <v-toolbar-title>Sub Activities</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click.native="SubActDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row class="pa-3">
              <!-- sub activities -->
              <v-col cols="4">
                <v-autocomplete
                  outlined
                  dense
                  v-model="SubAct.key"
                  :items="SubActivitiesMaster"
                  return-object
                  item-text="Title"
                  item-value="Keys"
                  label="Sub Activity"
                ></v-autocomplete>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  dense
                  label="Status"
                  outlined
                  v-model="SubAct.Status"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Location"
                  outlined
                  v-model="SubAct.Location"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Comments"
                  outlined
                  v-model="SubAct.Comments"
                ></v-text-field>
              </v-col>

              <v-col cols="4">
                <v-text-field
                  dense
                  label="Latitude"
                  outlined
                  v-model="SubAct.Latitude"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  dense
                  label="Longitude"
                  outlined
                  v-model="SubAct.Longitude"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn class="mr-1" outlined color="blue" @click="saveData">
                  <v-icon left dark>mdi-content-save</v-icon>ok
                </v-btn>
              </v-col>

              <!-- end -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- end of dialog -->
    </v-card-text>
    <snackbar refs="snackbar"></snackbar>
  </v-card>
</template>

<script>
export default {
  computed: {},
  props: {
    initial: {
      type: Object,
    },
  },
  data: () => ({
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    SubActDialog: false,
    modalDialog: false,
    menu2: false,
    search: null,
    selectionType: "leaf",
    loader: false,
    Users: [],
    DocItems: [],
    documents: [],
    bpMasterData: [],
    territories: [],
    record: {},
    region: {},
    trueValue: true,
    territory: {},
    dialog: false,
    modal2: false,
    time: null,
    ActivityType: [],
    SubActivities: [],
    ActivitySubjects: [],
    ActivityActions: [],
    SubActivityRows: [],
    SubAct: {},
    SubActivitiesMaster: [],
    Priorities: [
      { name: "Low", value: 0 },
      { name: "Normal", value: 1 },
      { name: "High", value: 2 },
    ],
    repeatData: [
      { name: "Never", value: "N" },
      { name: "Daily", value: "D" },
      { name: "Weekly", value: "W" },
      { name: "Monthly", value: "M" },
      { name: "Annually", value: "A" },
    ],
    CallActions: [
      { name: "C", value: "Phone Call" },
      { name: "E", value: "Note" },
      { name: "M", value: "Meeting" },
      { name: "N", value: "Other" },
      { name: "P", value: "Campaign" },
      { name: "T", value: "Task" },
    ],
    rows: [{ Objective: "" }],
    headers: [
      { text: "#", value: "id" },
      { text: "Document Type", value: "DocType", sortable: false },
      { text: "Document Number", value: "DocNum", sortable: false },
      { text: "Actions", value: "actions", sortable: false },
    ],
    SubActivityHeaders: [
      { text: "Title", value: "Title" },
      { text: "Key", value: "key" },
      { text: "Status", value: "Status" },
      { text: "Comments", value: "Comments", sortable: false },
      { text: "Location", value: "Location" },
      { text: "Latitude", value: "Latitude" },
      { text: "Longitude", value: "Longitude" },
      { text: "Open DateTime", value: "OpenDateTime" },
      { text: "Close DateTime", value: "CloseDateTime" },
      { text: "Action", value: "index" },
    ],
    DocRows: [
      {
        DocType: "",
        DocNum: "",
        dialogVisible: false,
        Status: false,
        DocItems: [],
      },
    ],
    currentrow: 0,
    selectedOption: null,
    saveOptions: [
      { text: "Save", value: "save" },
      { text: "Save as Draft", value: "draft" },
    ],
  }),
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      this.setup = this.record?.setup;
      this.rows = Array.isArray(val.objectives) ? [...val.objectives] : [];
      this.SubActivityRows = Array.isArray(val.sub_activity_rows)
        ? [...val.sub_activity_rows]
        : [];

      console.log(this.rows);
    },
    addRow() {
      this.rows.push({ Objective: "" });
    },
    removeRow(index) {
      this.rows.splice(index, 1);
    },

    addDocRow() {
      this.DocRows.push({
        DocType: "",
        DocNum: "",
        dialogVisible: false,
        Status: false,
      });
    },
    removeDocRow(index) {
      this.DocRows.splice(index, 1);
    },
    openDialog(index) {
      this.DocRows[index].dialogVisible = true;
      console.log(this.DocRows[index].dialogVisible);
    },
    closeDialog(index) {
      this.DocRows[index].dialogVisible = false;
    },
    getSubItemsMaster(item, index) {
      const self = this;
      this.$store
        .dispatch(
          "get",
          `marketing/docs/${item.DocType}?isDoc=1&EndDate&${item.EndDate}&StartDate${item.StartDate}`
        )
        .then((res) => {
          self.DocRows[index].DocItems = res.ResponseData.data;
          this.closeDialog(index);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDocs() {
      const self = this;
      this.$store
        .dispatch("get", `/doc_model`)
        .then((res) => {
          self.documents = res;
          console.log(self.documents);
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    time(data) {
      if (data.timing == "start") {
        this.record.BeginTime = data.time;
      } else {
        this.record.ENDTime = data.time;
      }
    },
    openRegionModal() {
      this.dialog = true;
    },
    selectItem(data) {
      this.territory = data;
      const id = data.id;
      const self = this;
      self.loading = true;
      // api call
      this.$store
        .dispatch("get", `/territories/${id}`)
        .then((res) => {
          self.region = res;
          self.record.region = self.region;
          self.record.RouteCode = self.region.id;
          self.loading = false;
          self.regionDialog = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });

      // end
    },
    setDate(date) {
      this.record.CallDate = date;
    },
    sendData(option) {
      this.loader = true;
      let IsDraft;
      if (option === "save") {
        IsDraft = 0;
      } else if (option === "draft") {
        IsDraft = 1;
      }
      this.record.ObjType = 314;
      this.record.IsDraft = IsDraft;
      this.record.objectives = this.rows;
      this.record.linkedDocs = this.DocRows;
      this.record.SubActivityRows = this.SubActivityRows;
      this.$emit("data", this.record);
    },
    getUsers() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/users`)
        .then((res) => {
          self.Users = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getTerritories() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/territories`)
        .then((res) => {
          self.territories = res.ResponseData.territories;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getDealer() {
      const self = this;
      self.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.bpMasterData = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getActivityStatus() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/activity-status`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.ActivityType = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    getSubjects() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/subjects_and_actions?Type=1`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.ActivitySubjects = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    getActions() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/subjects_and_actions?Type=2`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.ActivityActions = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    sub_activities() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/sub_activities`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.SubActivitiesMaster = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    GetSubActivities() {
      const self = this;
      self.loader = true;
      this.$store
        .dispatch("get", `/call/${this.$route.params.id}/sub_activities`)
        .then((res) => {
          self.loader = false;
          if (res.ResultCode == 1200) {
            self.SubActivities = res.ResponseData;
          } else {
            self.$refs.snackbar.show(res.ResultDesc, "red");
          }
        })
        .catch((err) => {
          self.loader = false;
          this.$refs.snackbar.show(err.response.data.errors, "red");
        });
    },
    saveData() {
      if (this.SubAct.key) {
        console.log(this.SubAct.key);
        this.SubActivityRows.push({
          Title: this.SubAct.key.Title,
          key: this.SubAct.key.key,
          Status: this.SubAct.Status,
          Location: this.SubAct.Location,
          Comments: this.SubAct.Comments,
          Latitude: this.SubAct.Latitude,
          Longitude: this.SubAct.Longitude,
        });
        this.SubAct = {};
        console.log(this.SubActivityRows);
        this.SubActDialog = false;
      } else {
        alert("Cannot add a row without key", "red");
      }
      // const self = this;
      // let Title = this.SubActivities.find((item) => item.key == this.SubAct.key);
      // this.SubAct.DocEntry = this.$route.params.id;
      // console.log(Title);
      // const data = this.SubAct;
      // let url = "call/sub_activities";
      // self.loader = true;
      // this.$store
      //   .dispatch("post", { url, data })
      //   .then((res) => {
      //     self.loader = false;
      //     if (res.ResultCode == 1200) {
      //       console.log(res.ResponseData);
      //     } else {
      //       self.$refs.snackbar.show(res.ResultDesc, "red");
      //     }
      //   })
      //   .catch((err) => {
      //     self.loader = false;
      //     this.$refs.snackbar.show(err.response.data.errors, "red");
      //   });
    },
    removeSubActRow(key) {
      this.SubActivityRows = this.SubActivityRows.filter((item) => item.key !== key);
    },
  },
  created() {
    this.getTerritories();
    this.getUsers();
    this.getDealer();
    this.getDocs();
    this.getActivityStatus();
    this.getSubjects();
    this.getActions();
    this.sub_activities();
    // this.GetSubActivities();
  },
};
</script>

<style lang="scss" scoped></style>
